(function() {
    'use strict';

    var page = {
        bindings: {
            page: '<'
        },
        controller: PageController,
        templateUrl: 'app/components/pages/page.html'

    };

    //console.log('PageController before ...');

    PageController.$inject = ['LayoutService', 'EmService', '$timeout', 'ScrollService', '$element'];
    /* @ngInject */
    function PageController(LayoutService, EmService, $timeout, ScrollService, $element) {
        
        console.log('**** PageController ****');
        
        var vm = this,
            initTimer,
            info,
            scrolled,
            delay = 1500;

        vm.$onInit = function () {
            console.log('PageController $onInit vm ', vm);

            console.log('PageController vm.$onInit $element.parent().parent().html().toString() ', $element.parent().parent().html().toString());

            resize(LayoutService.getLayoutInfo());

            LayoutService.bindResize('page', resize);

            ScrollService.bindOnce(onScroll);

            LayoutService.setBackground(vm.page.field_pozadi);
            LayoutService.onContentAnimationFinishSubscribe(onContentAnimationFinish, 'page');

            

        };

        vm.$postLink = function () {
            console.log('PageController vm.$postLInk info.firstState ', info.firstState);

            if (info.firstState) {

                $element.parent().css({position: 'relative'});

                animatePage(delay * 2);

                console.log('PageController vm.$postLInk $element.parent().parent().html().toString() ', $element.parent().parent().html().toString());


            }       
        };

        vm.$onDestroy = function () {
            console.log('PageController vm.$onDestroy ....');

            $timeout.cancel(initTimer);

            LayoutService.onContentAnimationFinishUnsubscribe('page');

            LayoutService.unBindResize('page');

            ScrollService.unBindOnce();

        };

        function animatePage(animDelay) {

            initTimer = $timeout(function() {

                console.log('PageController animatePage scrolled ', scrolled);

                if (!scrolled) {

                    var offset = info.elDimensions.mainHeader.height + EmService.getPx(10);

                    //console.log('PageController animatePage offset ', offset);

                    var scrollTo = info.dimensions.windowHeight - offset;

                    ScrollService.pageIn(scrollTo);

                    /*ScrollService.scrollTo({
                        scrollToY: scrollTo,
                        delay: 0,
                        duration: 1.8,
                        ease: Elastic.easeOut.config(1, 0.3)
                    });*/
                }

            }, animDelay, false);  

            

        }

        function onScroll() {
            //console.log('PageController onScroll initTimer ', initTimer);

            scrolled = true;

            if (initTimer) {
    
                $timeout.cancel(initTimer);

            }


        }

        function onContentAnimationFinish() {

            animatePage(delay)
            
        }

        function resize(layoutInfo) {

            info = layoutInfo;

            console.log('PageController resize info ', info);

            vm.windowHeight = info.dimensions.windowHeight;
            //vm.windowWidth = info.windowWidth;

        }
    }
 
    angular
        .module('app.layout')
        .component('page', page);


})();