(function() {
    'use strict';

    angular
        .module('app.layout')
        .constant('TweenLite', TweenLite)
        .constant('SVGMorpheus', SVGMorpheus)
        ;

        
})();
