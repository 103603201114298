(function () {
    'use strict';

    angular
        .module('app.core', [
            'ngAnimate', 
            'ngSanitize',
            'ui.router',
            'Scroll',
            'angular-bind-html-compile'
        ]);
})();
