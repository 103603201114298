(function () {
    'use strict';

    angular
        .module('app.core')
        .service('RestService', RestService);

    RestService.$inject = ['$http', 'config', '$q'];
    /* @ngInject */
    function RestService($http, config, $q) {

        var service = {
            getPages: getPages,
            submitForm: submitForm,
            storeForm: storeForm,
            getStoredForm: getStoredForm
        };

        var token/* = getToken()*/, // init auth
            formStorage = false;


        return service;

        function getPages() {

            return getData()
                .then(success)
                .catch(fail);


            function success(response) {
                //console.log('getPages response.data ' + response.data);

                return sortPages(response.data);
            }
                
            function fail(error) {
                $q.reject('getPages error ' + JSON.stringify(error));
            }

            function sortPages(data) {

                var pages = {};

                angular.forEach(data, function(obj, key) {
                    //console.log('sortPages obj', obj);
                    //console.log('sortPages key', key);

                    pages[obj.field_sablona] = obj;

                });

                return pages;
            }    
        }

        function getData() {
            //console.log('getPages');

            var setting = {
                method: 'GET',
                url: config.basePath + '/data'
            };

            //console.log('getPages setting.url ' + setting.url);

            return $http(setting);

            
        }

        function submitForm(form) {
        //console.log('submitForm form ', form);

            var setting = {
                method: 'POST',
                url: config.basePath + '/contact_message/',
                headers: {

                    'X-CSRF-Token' : token,
                        
                    'Content-Type': 'application/json'
                },
                data: form

            };

            return $http(setting)
                .then(success);

            function success(response) {
            //console.log('submitForm response.data ', response.data);

                formStorage = false;

                return response;
            }
                
            function fail(error) {
                $q.reject('submitForm error');
            }  

        }

        function storeForm(form) {
            formStorage = form;
        }

        function getStoredForm() {
            return formStorage;
        }

        function getToken() {
            //console.log('getToken');

            var setting = {
                method: 'GET',
                url: config.basePath + '/session/token'
            };

            return $http(setting)
                .then(success)
                .catch(fail);

            function success(response) {
            //console.log('getToken response.data ', response.data);

                //token = response.data;

                return response.data;
            }
                
            function fail(error) {
                $q.reject('getToken error');
            }   

        }
        
    }
})();
