(function() {
    'use strict';

    angular
        .module('app.layout')
        .animation('.background-animation', backgroundAnimation);

    backgroundAnimation.$inject = ['TweenLite'];
    /* @ngInject */   
    function backgroundAnimation(TweenLite) {

      var animationDuration = 3,
        counter = 0;

      return {

        // ************ ENTER ************
        enter : function(element, done) {
          console.log('BackgroundAnimation enter element ', element);
          //console.log('BackgroundAnimation enter counter ', counter);
          
          TweenLite.set(element, {'z-index': counter, opacity: 0});

          counter++;

          // @***ENTER***

          var enter = new TimelineLite({paused:true});

          enter.to(element, animationDuration, {opacity:1, ease:Power1.ease}, '+=0')
            .call(done,[], this, '+=1'); 

          // @end***ENTER***

          enter.play(); 

        },

        // ************ LEAVE ************
        leave : function(element, done) {
          //console.log('backgroundAnimation leave element ', element);
          
          // @***LEAVE***

          var leave = new TimelineLite({paused:true});

          leave
            .call(done,[], this, '+=' + animationDuration + 1);
          
          // @end***LEAVE***  

          leave.play();

        } 
      };
    }   
})();




