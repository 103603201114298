(function () {
    'use strict';

    var config = {

        appTitle: 'Aluna',

        /* DEVELOP */

        /*basePath: 'http://localhost/aluna',
        rootPath: 'http://localhost'*/

        /* PRODUCTION */

        basePath: 'https://www.nahravacistudioaluna.cz',
        rootPath: 'https://www.nahravacistudioaluna.cz'

    };
    
    angular
        .module('app.core')
        .value('config', config)
        .config(configure)
        .config(restfulConfig)
        .config(RAF)
        .config(compileProvider)
        //.run(routerTrace)
        //.run(animate)
        ;


    
    animate.$inject = ['$animate'];
    /* @ngInject */    
    function animate($animate) {
        
        $animate.enabled(true);
    }

    compileProvider.$inject = ['$compileProvider'];
    /* @ngInject */    
    function compileProvider($compileProvider) {
        
        $compileProvider.debugInfoEnabled(false);
    }

    routerTrace.$inject = ['$trace', '$uiRouter'];
    /* @ngInject */    
    function routerTrace($trace, $uiRouter) {
        
        $trace.enable('TRANSITION', 'VIEWCONFIG');

        //var pluginInstance = $uiRouter.plugin(Visualizer);
    }    

    configure.$inject = ['$locationProvider', '$urlRouterProvider'];
    /* @ngInject */
    function configure($locationProvider, $urlRouterProvider) {

        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });

        //$urlRouterProvider.deferIntercept();

        $urlRouterProvider.otherwise("/uvod");

    }

    restfulConfig.$inject = ['$httpProvider'];
    /* @ngInject */
    function restfulConfig($httpProvider) {
            // Need to connect to Drupal site.
            delete $httpProvider.defaults.headers.common['X-Requested-With'];
            // Set Accept header to hal+json to comply with Drupal GET
            $httpProvider.defaults.headers.common['Accept'] = 'application/json';
    }

    RAF.$inject = ['$windowProvider'];
    /* @ngInject */
    function RAF($windowProvider) {

        var $window = $windowProvider.$get();

        

        // http://paulirish.com/2011/requestanimationframe-for-smart-animating/
        // http://my.opera.com/emoller/blog/2011/12/20/requestanimationframe-for-smart-er-animating
         
        // requestAnimationFrame polyfill by Erik Möller
        // fixes from Paul Irish and Tino Zijdel    

        var lastTime = 0;

        $window.requestAnimationFrame = false;
        $window.cancelAnimationFrame = false;

        var vendors = ['ms', 'moz', 'webkit', 'o'];
        for(var x = 0; x < vendors.length && !$window.requestAnimationFrame; ++x) {
            $window.requestAnimationFrame = $window[vendors[x]+'RequestAnimationFrame'];
            $window.cancelAnimationFrame = $window[vendors[x]+'CancelAnimationFrame'] || 
            $window[vendors[x]+'CancelRequestAnimationFrame'];
        }
     
        if (!$window.requestAnimationFrame) {
          $window.requestAnimationFrame = function(callback, element) {
              var currTime = new Date().getTime();
              var timeToCall = Math.max(0, 16 - (currTime - lastTime));
              var id = $window.setTimeout(function() { 

                //console.log('RAF setTimeout callback ' + callback);

                callback(currTime + timeToCall); 
            },
                timeToCall);
              lastTime = currTime + timeToCall;
              return id;
          };
        }
        if (!$window.cancelAnimationFrame) {
          $window.cancelAnimationFrame = function(id) {

            //console.log('RAF clearTimeout id ' + id);

              clearTimeout(id);
          };
        }

        window.requestAnimationFrame = false;
        window.cancelAnimationFrame = false;
        
        if (!window.requestAnimationFrame) {
          window.requestAnimationFrame = function(callback, element) {
              var currTime = new Date().getTime();
              var timeToCall = Math.max(0, 16 - (currTime - lastTime));
              var id = window.setTimeout(function() { 

                //console.log('RAF setTimeout callback ' + callback);

                callback(currTime + timeToCall); 
            },
                timeToCall);
              lastTime = currTime + timeToCall;
              return id;
          };
        }
        if (!window.cancelAnimationFrame) {
          window.cancelAnimationFrame = function(id) {

            //console.log('RAF clearTimeout id ' + id);

              clearTimeout(id);
          };
        }

         //console.log('RAF' + $window.requestAnimationFrame);
    }

})();
