(function () {
    'use strict';

    LayoutService.$inject = ['config', '$transitions', '$state', '$window', 'EmService'];
    /* @ngInject */
    function LayoutService(config, $transitions, $state, $window, EmService) {
        var service = {
            getLayoutInfo: getLayoutInfo,

            elementDimensionsSubscribe: elementDimensionsSubscribe,
            elementDimensionsUnSubscribe: elementDimensionsUnSubscribe,

            setBackground: setBackground,
            setBackgroundSubscribe: setBackgroundSubscribe,

            swipeToState: swipeToState,

            getFlatRoutes: getFlatRoutes,
            getStateIndex: getStateIndex,
            
            disableKeyboard: disableKeyboard,

            onContentAnimationFinish: onContentAnimationFinish,
            onContentAnimationFinishSubscribe: onContentAnimationFinishSubscribe,
            onContentAnimationFinishUnsubscribe: onContentAnimationFinishUnsubscribe,

            bindResize: bindResize,
            unBindResize: unBindResize
        };

        var currentBackground,
            setBackgroundCallback,
            flatRoutes = flattenRoutes($state.get()),
            visitedRoutes = {},
            keyDisabled,
            onContentAnimationFinishCallback = {},
            bindResizeCallback = {},
            resizeCallbacks = {},
            wait,
            init,

            $event = {
                directionUp: true,
                border: false,
                visited: false,
                dimensions: {},
                firstState: true,
                elDimensions: {},
                swiped: false,
                keyboard: false
            };

        $transitions.onBefore({ }, onTransitionBefore); 
        angular.element($window).on('resize', throttleResize);  
        document.onkeydown = keyboardControl; 

        /* INIT */
        init(); 

        return service;

            function getLayoutInfo() {

                return $event;

            }

            function bindResize(id, fn) {

                bindResizeCallback.id = fn;
            }

            function unBindResize(id) {

                delete bindResizeCallback.id;
            }

            function resize() {
                //console.log('LayoutService RESIZED');

                $event.dimensions = windowDimensions();

                EmService.resize();

                angular.forEach(resizeCallbacks, function(fn, key) {
                    //console.log('LayoutService resize key', key);

                    $event.elDimensions[key] = fn();

                });

                angular.forEach(bindResizeCallback, function(fn, key) {
                    //console.log('LayoutService resize key', key);

                    fn($event);

                });

            }

            function elementDimensionsSubscribe(elName, getDimensions, dimensions) {

                $event.elDimensions[elName] = dimensions;

                resizeCallbacks[elName] = getDimensions;

                //console.log('LayoutService elementDimensionsSubscribe $event ', $event);
            }

            function elementDimensionsUnSubscribe(elName) {

                delete $event.elDimensions[elName];

                delete $event.resizeCallbacks[elName];
            }

            function init() {
                
                $event.dimensions = windowDimensions();
            }

            function throttleResize() {
                //console.log('LayoutService throttleResize ...');

                clearTimeout(wait);
                wait = setTimeout(resize, 100);

            }

            function setBackground(image) {
            //console.log('LayoutService setBackground image ' + image);

                if (currentBackground !== image) {
                    
                    currentBackground = image;

                    setBackgroundCallback([config.rootPath + image]);
                }
                
            }

            function setBackgroundSubscribe(fn) {
            //console.log('LayoutService setBackground fn ' + fn);

                setBackgroundCallback = fn;
            }

            function onContentAnimationFinish(id) {

                angular.forEach(onContentAnimationFinishCallback, function(fn, key) {

                    //console.log('LayoutService onContentAnimationFinish key', key);

                    fn();

                });
            }

            function onContentAnimationFinishSubscribe(fn, id) {
            //console.log('LayoutService onContentAnimationFinishCallback ' + id);

                onContentAnimationFinishCallback[id] = fn;
            }

            function onContentAnimationFinishUnsubscribe(id) {

                delete onContentAnimationFinishCallback[id];

            }

            function swipeToState(index) {
                //console.log('LayoutService setBackground index', index);

                index = normalizeIndex(index);

                $event.swiped = true;

                $state.go(flatRoutes[index]);
            }

            function getFlatRoutes() {
                return flatRoutes;
            }

            function getStateIndex() {
                return flatRoutes.indexOf($state.current.name);
            }

            /* PRIVATE */

            function onTransitionBefore(trans) {
                //console.log('layoutService onTransitionBefore trans ', trans);
                //console.log('layoutService onTransitionBefore trans.params(from) ', trans.params('from'));
                //console.log('layoutService onTransitionBefore trans.params(to) ', trans.params('to'));
                //console.log('layoutService onTransitionBefore trans.from().name ', trans.from().name);
                //console.log('layoutService onTransitionBefore trans.to() ', trans.to().type);
                //console.log('layoutService onTransitionBefore facets ', facets);

                //console.log('layoutService onTransitionBefore $event.firstState ', $event.firstState);

                /* INIT */

                if (trans.from().name !== '' && $event.firstState) {
                    $event.firstState = false;
                }

                
                /* ANIMATION DIRECTION */

                if (trans.from().data) {
                    if (trans.from().data.nav < trans.to().data.nav) {
                        $event.directionUp = true;
                    }

                    else {
                        $event.directionUp = false;
                    }
                }


                /* SWIPE THROUGH BORDER */

                if (!$event.swiped) {

                    $event.swiped = false;

                }


                /* KEYBOARD NAVIGATION */

                if (!$event.keyboard && !$event.swiped) {

                    $event.border = false;

                }

                $event.swiped = false;
                $event.keyboard = false;


                /* VISITED ROUTES */

                if (visitedRoutes[trans.to().name] === undefined) {

                    visitedRoutes[trans.to().name] = true;

                    $event.visited = false;

                }

                else {
                    $event.visited = true;
                }
                
            }

            function flattenRoutes(routes) {
                    
                var flatRoutes = [];

                angular.forEach(routes, function(obj, key) {
                    
                    if (obj.menu === 'root') {

                        flatRoutes.push(obj.name);
                    }
                });

                return flatRoutes;
            }

            function windowDimensions() {
                var dimensions = {};

                dimensions.windowHeight = $window.innerHeight;
                dimensions.windowWidth = $window.innerWidth;

                return dimensions;
            }

            function normalizeIndex(index) {

                if (index < 0) {
                        
                    index = flatRoutes.length - 1;

                    $event.border = true;
                }

                else if (index > flatRoutes.length - 1) {
                    
                    index = 0;

                    $event.border = true;
                }

                else {
                    $event.border = false;
                }

                return index;
            }


            // KEYBOARD NAVIGATION
            function keyboardControl(event) {

                if (keyDisabled) {return;}

                var index = getStateIndex(),
                    keyCode = event.keyCode;

                //console.log('keyBoard keyCode', keyCode);
                //console.log('keyBoard index', index);

                if (keyCode === 37) {
                    index--;
                    
                }
                else if (keyCode === 39) {
                    index++;
                    
                }
                else {return;}

                $event.keyboard = true;

                index = normalizeIndex(index);

                $state.go(flatRoutes[index]);          

            }

            function disableKeyboard (disable) {
                keyDisabled = disable;
            }
        
    }

    /* EM SERVICE */

//console.log('EmService before...');

    EmService.$inject = ['$document'];
    /* @ngInject */
    function EmService($document) {
    //console.log('EmService...');

        var $em = angular.element('<em></em>'),
            emCache;

        var service = {
            getPx: getPx,
            getEm: getEm,
            resize: resize
        };

        init();

        return service;

        function getEm () {
            //console.log('EmService getEm emCache ', emCache);

            return emCache;
        }

        function getPx (value) {
            //console.log('EmService getPx emCache ', emCache);

            return value * emCache;
        }

        function resize() {

            emCache = $em[0].clientWidth;

            //console.log('EmService resize emCache ', emCache);
        }

        /* PRIVATE */

        function init () {
        //console.log('EmService init...');
            
            var body = $document.find('body').eq(0);

            $em.css({position: 'absolute', top: '0em', left: '0em', height: 0, width: '1em', visibility: 'hidden'});

            body.append($em);

            resize();
        } 
    }

    angular
        .module('app.layout')
        .service('EmService', EmService)
        .service('LayoutService', LayoutService)
        ;

})();
