(function() {
    'use strict';

    angular
        .module('app.layout')
        .animation('.content-animation', contentAnimation);

    contentAnimation.$inject = ['TweenLite', 'LayoutService', 'EmService', 'ScrollService'];
    /* @ngInject */   
    function contentAnimation(TweenLite, LayoutService, EmService, ScrollService) {

      var animationDuration = 1.2;

      return {

        // ************ ENTER ************
        enter : function($element, done) {
          console.log('contentAnimation enter $element ' + $element);
          //console.log('contentAnimation enter $element[0].innerHTML ', $element[0].innerHTML);
          //console.log('contentAnimation enter LayoutService.getLayoutInfo() ', LayoutService.getLayoutInfo());

            console.log('contentAnimation enter $element.parent().parent().html().toString() ', $element.parent().parent().html().toString());


          var offsetX = $element[0].offsetWidth;
          var offsetY = 0;
          var info = LayoutService.getLayoutInfo();
          var $page = $element[0].querySelector('.page');

          //console.log('contentAnimation enter $page ' + $page);

          if (!$page) {

            //console.log('contentAnimation enter CALL done() $page ' + $page);
            
            done();
            
          } else {

            if (info.directionUp) {
              offsetX = -offsetX;
            }

            if (info.border) {
              offsetX = -offsetX;
            }

            if (info.visited) {
              
              var headerHeight = info.elDimensions.mainHeader.height + EmService.getPx(10);

              offsetY = info.dimensions.windowHeight - headerHeight;
            }

            //TweenLite.set($element, {position: 'fixed'});
            TweenLite.set($page, {x: -offsetX, y: -offsetY});

            // @***ENTER***

            var enter = new TimelineLite({paused:true});

            enter

              .to($page, animationDuration, {x:0, ease:Power4.easeInOut}, '+=' + 0)

              .call(scrollPage,[], this, '+=' + 0.3)

              .set($page, {clearProps:'all'}, '+=' + 0.5)
              .set($element, {/*clearProps:'all',*/ position: 'relative'}, '+=' + 0)
              .call(done,[], this, '+=' + 0)

              ; 

            // @end***ENTER***

            enter.play(); 

          }

          function scrollPage() {


            //$window.scrollTo(0, offsetY);


            var $event = {
                scrollToY: offsetY,
                duration: 0
            };

            ScrollService.scrollTo($event);
              
          }
          
          // ENTER CALLBACK
          return function(cancelled) {
            //console.log('contentAnimation enter done cancelled', cancelled);

            if ($page) {

                LayoutService.onContentAnimationFinish();

            }


          };

        },

        // ************ LEAVE ************
        leave : function($element, done) {
          //console.log('contentAnimation leave $element ', $element);

          var height = $element[0].scrollHeight;
          var info = LayoutService.getLayoutInfo();
          var $page = $element[0].querySelector('.page');

          //console.log('contentAnimation leave $page ', $page);

          if ($page === null) {return;}


          if (info.swiped) {

            // @***swipe***

            var swipe = new TimelineLite({paused:true});

            swipe
              .call(done,[], this, '+=' + animationDuration + 0.3);
            
            // @end***swipe***  

            swipe.play();

          }

          else {
            
            var offsetX = $element[0].offsetWidth;

            if (info.directionUp) {
              offsetX = -offsetX;
            }

            if (info.border) {
              offsetX = -offsetX;
            }
            
            // @***LEAVE***

            var leave = new TimelineLite({paused:true});

            leave
              .to($page, animationDuration, {x:offsetX, ease:Power4.easeInOut}, '-=' + 0)
              .call(done,[], this, '+=' + 0.3 );
            
            // @end***LEAVE***  

            leave.play();

            // LEAVE CALLBACK
            return function(cancelled) {
              //console.log('contentAnimation leave done cancelled', cancelled);

            };
          }         
        } 
      };
    }    
})();




