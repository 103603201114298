(function() {
    'use strict';

    var mainHeader = {
        controller: MainHeaderController,
        templateUrl: 'app/components/layout/main-header.html'

    };

    MainHeaderController.$inject = ['$element', 'ScrollService', '$transitions', 'TweenLite', 'LayoutService', '$timeout'];
    /* @ngInject */
    function MainHeaderController($element, ScrollService, $transitions, TweenLite, LayoutService, $timeout) {
        

        
        var vm = this,
            $navigation,
            lastScroll = false,
            $logo,
            navAnimation = new TimelineLite({paused:true});


        vm.$onInit = function () {
            //console.log('MainHeaderController $onInit this ', this);
            //console.log('MainHeaderController $onInit vm.states ', vm.states);

            
            vm.mobileTitle = 'otevřít nabídku';
        };

        vm.$postLink = function () {

            $logo = $element[0].querySelector('.logo');

            $navigation = angular.element($element.parent()[0].querySelector('.main-footer'));
            $navigation.quietScroll = false;

            $transitions.onSuccess({ }, onTransitionSuccess);

            LayoutService.onContentAnimationFinishSubscribe(onContentAnimationFinish, 'navigation');
            LayoutService.bindResize('MainHeader', resetMenuButton);

            LayoutService.elementDimensionsSubscribe('mainHeader', resizeCallback, resizeCallback());

            navAnimation

              .set($navigation, {clearProps: 'all'})  
              .to($navigation, 0.6, {y: '100%', ease: Power3.easeIn})
              .set($navigation, {pointerEvents: 'none', visibility: 'hidden'})

            ; 



            //console.log('MainHeaderController vm.mobileMenuOpen $navigation ', $navigation);

            //console.log('***** ***** ***** MainHeaderController $logo.offsetHeight', $logo.offsetHeight);
        }

        vm.$onDestroy = function () {
            LayoutService.unBindResize('MainHeader');
        }

        vm.mobileMenuOpen = function () {

        //console.log('MainHeaderController vm.mobileMenuOpen this ', this);
        //console.log('MainHeaderController vm.mobileMenuOpen lastScroll ', lastScroll);

            toggleMenu();

        };

        function resetMenuButton(layoutInfo) {
        //console.log('MainHeaderController resetMenuButton lastScroll ', lastScroll);

            if (lastScroll !== false) {

                $timeout(function() {

                    vm.icon = 'menu';
                    vm.mobileTitle = 'otevřít nabídku';

                }, 0, true);

                lastScroll = false;

                ScrollService.unBindElementTopPass();
            }

        }

        function onTransitionSuccess(trans) {

            //console.log('MainHeaderController onTransitionSuccess trans.from() ', trans.from());

            if (trans.from().name === '') return;

            resetMenuButton();

            navAnimation.play();

            //TweenLite.set($navigation, {pointerEvents: 'none'});
            //TweenLite.fromTo($navigation, 0.6, {pointerEvents: 'none', y: '0%'}, {y: '100%', ease: Power3.easeIn});

        }

        function onContentAnimationFinish() {

            //TweenLite.to($navigation, 0.6, {y: '0%', visibility: 'visible', clearProps: 'all', ease: Power4.easeOut}, '+=0.3');
            //TweenLite.set($navigation, {pointerEvents: 'all'});
            
            navAnimation.reverse();

        }

        function toggleMenu() {
            //console.log('MainHeaderController toggleMenu Math.floor($navigation[0].getBoundingClientRect().top) ', Math.floor($navigation[0].getBoundingClientRect().top));

            var e,
                navTop = Math.floor($navigation[0].getBoundingClientRect().top);

            $navigation.importance = 1;
            $navigation.duration = 0.8;
            $navigation.ease = Power4.easeInOut;           


            if (lastScroll !== false) {

                vm.icon = 'menu';
                vm.mobileTitle = 'otevřít nabídku';

                $navigation.scrollToY = lastScroll;
                $navigation.callBack = false;

                lastScroll = false;

                ScrollService.unBindElementTopPass();
                ScrollService.scrollToElement($navigation);

            }
            else if (navTop > 0) {
                
                vm.icon = 'close';
                vm.mobileTitle = 'zpět na stránku';

                lastScroll = ScrollService.get(e).y;

                $navigation.scrollToY = undefined;
                $navigation.callBack = scrollCallback;

                ScrollService.scrollToElement($navigation);

            }
            else if (navTop < 0) {

                $navigation.scrollToY = undefined;

                ScrollService.scrollToElement($navigation);       
            }

            //console.log('MainHeaderController vm.mobileMenuOpen lastScroll ', lastScroll);

        }

        function scrollCallback() {
        //console.log('MainHeaderController scrollCallback lastScroll ', lastScroll);

            ScrollService.bindElementTopPass($navigation, resetMenuButton);
        }

        function resizeCallback() {
            //console.log('MainHeaderController scrollCallback $logo ', $logo);

            return { height: $logo.offsetHeight };
        }
      
    }
 
    angular
        .module('app.layout')
        .component('mainHeader', mainHeader);


})();