(function() {
    'use strict';

    var icon = {
            bindings: {
                    icon: '<',
                    default: '@',
                    delay: '<'
            },
            controller: IconController,
            templateUrl: 'app/components/layout/icon.html'

    };

    IconController.$inject = ['$element', 'SVGMorpheus', '$timeout'];
    /* @ngInject */
    function IconController($element, SVGMorpheus, $timeout) {

        var vm = this,
            $iconSet;

        vm.$postLink = function () {
            //console.log('IconController vm.$postLink ' + vm.default);
            //console.log('IconController vm.$postLink START SVGMorpheus ' + SVGMorpheus);

            var $icon = $element[0].querySelector('.menu-icon');

            $iconSet = new SVGMorpheus($icon);

            //console.log('IconController vm.$postLink $iconSet.to ' + $iconSet.to);


            if (vm.delay === undefined) {
                $iconSet.to(vm.default, {duration: 400, easing: 'cubic-out', rotation: 'none'});
            }

            else {
                $timeout(function() {

                    $iconSet.to(vm.default, {duration: 400, easing: 'cubic-out', rotation: 'none'});
                
                }, vm.delay, false);
            }

            vm.init = true;

            //console.log('IconController vm.$postLink SVGMorpheus ' + SVGMorpheus);

        };

        vm.$onChanges = function (changes) {
            //console.log('IconController onChanges changes ', changes);

            if (changes.icon) {
                //console.log('IconController onChanges changes.icon.currentValue ', changes.icon.currentValue);

                if (changes.icon.currentValue !== undefined && vm.init) {

                    $iconSet.to(vm.icon, {duration: 400, easing: 'cubic-in-out', rotation: 'clock'});

                }
            }
        };
    }

    angular
        .module('app.layout')
        .component('icon', icon);


})();