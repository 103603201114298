(function() {
    'use strict';

    var nav = {
        controller: NavigationController,
        templateUrl: 'app/components/layout/navigation.html'

    };

    NavigationController.$inject = ['$state', '$element', 'ScrollService'];
    /* @ngInject */
    function NavigationController($state, $element, ScrollService) {
        //console.log('NavigationController START $scope ', $scope);
        
        var vm = this;

        vm.$onInit = function () {
            //console.log('NavigationController $onInit this ', this);

            vm.states = $state.get();

        //console.log('NavigationController $onInit vm.states ' + vm.states);

        };
    }
 
    angular
        .module('app.layout')
        .component('nav', nav);


})();