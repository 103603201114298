(function() {
    'use strict';

    angular
        .module('app.core')
        .config(configRoutes);

    configRoutes.$inject = ['$stateProvider'];    
    /* @ngInject */
    function configRoutes($stateProvider) {

        var noWaitPolicy = { when: "EAGER", async: "NOWAIT" };

        $stateProvider

            .state('app', {
                //abstract: true,
                resolve: {pages: pages},
                resolvePolicy: noWaitPolicy
            })

            .state('home', {
                url: '/uvod',
                menu: 'root',
                parent: 'app',
                data: {
                    title: 'Úvod',
                    type: 'page',
                    nav: 100
                },
                views: {
                    'page@' : 'page'
                },
                resolve:{
                    page: function(pages){
                        console.log('home resolve pages ' + pages.home.field_pozadi);
                        return pages.home;
                    }
                }
            })

            .state('about_studio', {
                url: '/o-studiu',
                menu: 'root',
                parent: 'app',
                data: {
                    title: 'O studiu',
                    type: 'page',
                    nav: 200
                },
                views: {
                    'page@' : 'page'
                },
                resolve:{
                    page: function(pages){
                        return pages.about_studio;
                    }
                }
            })

            .state('praha', {
                url: '/tocba_v_praze',
                menu: 'root',
                parent: 'app',
                data: {
                    title: 'Točba v Praze',
                    type: 'page',
                    nav: 300
                },
                views: {
                    'page@' : 'page'
                },
                resolve:{
                    page: function(pages){
                    //console.log('home resolve pages ', pages);
                        return pages.praha;
                    }
                }
            })


            .state('ochutnavky', {
                url: '/ochutnavky',
                menu: 'root',
                parent: 'app',
                data: {
                    title: 'Ochutnávky',
                    type: 'page',
                    nav: 400
                },
                views: {
                    'page@' : 'page'
                },
                resolve:{
                    page: function(pages){
                    //console.log('home resolve pages ', pages);
                        return pages.ochutnavky;
                    }
                }
            })

            .state('nadobicko', {
                url: '/nadobicko',
                menu: 'root',
                parent: 'app',
                data: {
                    title: 'Nádobíčko',
                    type: 'page',
                    nav: 500
                },
                views: {
                    'page@' : 'page'
                },
                resolve:{
                    page: function(pages){
                    //console.log('home resolve pages ', pages);
                        return pages.nadobicko;
                    }
                }
            })

            .state('dobrozdani', {
                url: '/dobrozdani',
                menu: 'root',
                parent: 'app',
                data: {
                    title: 'Dobrozdání',
                    type: 'page',
                    nav: 600
                },
                views: {
                    'page@' : 'page'
                },
                resolve:{
                    page: function(pages){
                    //console.log('home resolve pages ', pages);
                        return pages.dobrozdani;
                    }
                }
            })

            .state('jak_na_to', {
                url: '/jak_na_to',
                menu: 'root',
                parent: 'app',
                data: {
                    title: 'Jak na to',
                    type: 'page',
                    nav: 700
                },
                views: {
                    'page@' : 'page'
                },
                resolve:{
                    page: function(pages){
                    //console.log('home resolve pages ', pages);
                        return pages.jak_na_to;
                    }
                }
            })


            .state('cenik', {
                url: '/cenik',
                menu: 'root',
                parent: 'app',
                data: {
                    title: 'Ceník',
                    type: 'page',
                    nav: 800
                },
                views: {
                    'page@' : 'page'
                },
                resolve:{
                    page: function(pages){
                    //console.log('cenik resolve pages ' + pages);
                        return pages.cenik;
                    }
                }
            })

            .state('contact', {
                url: '/kontakt',
                menu: 'root',
                parent: 'app',
                data: {
                    title: 'Kontakt',
                    type: 'page',
                    nav: 900
                },
                views: {
                    'page@' : 'page'
                },
                resolve:{
                    page: function(pages){
                        return pages.contact;
                    }
                }
            })
        ;

    }

    pages.$inject = ['RestService'];    
    /* @ngInject */
    function pages(RestService) {
        //console.log('$stateProvider resolve pages ...');
        return RestService.getPages();
    }
    
})();