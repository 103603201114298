(function() {
    'use strict';

    BodyController.$inject = ['$element'];
    /* @ngInject */
    function BodyController($element) {
        
        var vm = this;

        //vm.background = '';

        vm.$onInit = function () {
            //console.log('BodyController $onInit vm ', vm);

            //LayoutService.setBackgroundSubscribe(setBackground);

            //SwipeService.bind($element, '.page');
        };
    }
 
    angular
        .module('app.layout')
        .controller('BodyController', BodyController);


})();