(function() {
    'use strict';

    angular
        .module('app.layout')
        .directive('pinchToZoom', pinchToZoom);

    pinchToZoom.$inject = ['$swipe'];  
    /* @ngInject */
    function pinchToZoom ($swipe) {
        //console.log('pinchToZoom...');

        var directive = {
            link: link,
            restrict: 'A'
        };

        return directive;

        function link(scope, $element, attrs) {
            console.log('pinchToZoom $element', $element);
            //console.log('pinchToZoom scope.windowWidth', scope.windowWidth);
            console.log('pinchToZoom attrs', attrs);

            var elWidth, elHeight;

            // mode : 'pinch' or 'swipe'
            var mode = '';

            // distance between two touche points (mode : 'pinch')
            var distance = 0;
            var initialDistance = 0;

            // image scaling
            var scale = 1;
            var relativeScale = 1;
            var initialScale = 1;
            var maxScale = parseInt(attrs.maxScale, 10);
            if (isNaN(maxScale) || maxScale <= 1) {
              maxScale = 2;
            }

            // position of the upper left corner of the element
            var positionX = 0;
            var positionY = 0;

            var initialPositionX = 0;
            var initialPositionY = 0;

            // central origin (mode : 'pinch')
            var originX = 0;
            var originY = 0;

            // start coordinate and amount of movement (mode : 'swipe')
            var startX = 0;
            var startY = 0;
            var moveX = 0;
            var moveY = 0;

            

            /*$swipe.bind($element, {
                start: swipeStart,
                move: swipeMove,
                end: swipeEnd,
                cancel: function(event) {
                    swipeEnd({}, event);
                }
            });

            $element.bind('mouseleave', function(event) {
                swipeEnd({}, event);
            });*/

            $element.bind('touchstart', swipeStart);
            $element.bind('touchmove', swipeMove);
            $element.bind('touchend', swipeEnd);

            /*$element.bind('mousedown', swipeStart);
            $element.bind('mousemove', swipeMove);
            $element.bind('mouseup', swipeEnd);*/

            function swipeStart(evt) {

              console.log('pinchToZoom swipeStart evt', evt);

              var touches = evt.originalEvent ? evt.originalEvent.touches : evt.touches;

              startX = touches[0].clientX;
              startY = touches[0].clientY;
              /*initialPositionX = positionX;
              initialPositionY = positionY;
              moveX = 0;
              moveY = 0;*/
            }

            /**
             * @param {object} evt
             */
            function swipeMove(evt) {
              var touches = evt.originalEvent ? evt.originalEvent.touches : evt.touches;

              if (mode === '') {
                if (touches.length === 1) {

                  mode = 'swipe';

                } else if (touches.length === 2) {

                  mode = 'pinch';

                  initialScale = scale;
                  initialDistance = getDistance(touches);
                  /*originX = touches[0].clientX -
                            parseInt((touches[0].clientX - touches[1].clientX) / 2, 10) -
                            element[0].offsetLeft - initialPositionX;
                  originY = touches[0].clientY -
                            parseInt((touches[0].clientY - touches[1].clientY) / 2, 10) -
                            element[0].offsetTop - initialPositionY;*/

                }
              }

              if (mode === 'swipe') {

                return;

                /*evt.preventDefault();

                moveX = touches[0].clientX - startX;
                moveY = touches[0].clientY - startY;

                positionX = initialPositionX + moveX;
                positionY = initialPositionY + moveY;

                transformElement();*/

              } else if (mode === 'pinch') {
                evt.preventDefault();

                distance = getDistance(touches);
                relativeScale = distance / initialDistance;
                scale = relativeScale * initialScale;

                /*positionX = originX * (1 - relativeScale) + initialPositionX + moveX;
                positionY = originY * (1 - relativeScale) + initialPositionY + moveY;*/

                transformElement();

              }
            }

            /**
             * @param {object} evt
             */
            function swipeEnd(evt) {
              var touches = evt.originalEvent ? evt.originalEvent.touches : evt.touches;

              if (mode === '' || touches.length > 0) {
                return;
              }

               /*else {

                if (positionX > 0) {
                  positionX = 0;
                } else if (positionX < elWidth * (1 - scale)) {
                  positionX = elWidth * (1 - scale);
                }
                if (positionY > 0) {
                  positionY = 0;
                } else if (positionY < elHeight * (1 - scale)) {
                  positionY = elHeight * (1 - scale);
                }

              }*/

              transformElement();
              mode = '';
            }

            /**
             * @param {Array} touches
             * @return {number}
             */
            function getDistance(touches) {
              var d = Math.sqrt(Math.pow(touches[0].clientX - touches[1].clientX, 2) +
                                Math.pow(touches[0].clientY - touches[1].clientY, 2));
              return parseInt(d, 10)/* * 0.3*/;
            }

            /**
             * @param {number} [duration]
             */
            function transformElement() {

                if (scale < 1) {

                    scale = 1;
                    /*positionX = 0;
                    positionY = 0;
                     */
                  } else if (scale > maxScale) {

                    scale = maxScale;
                    relativeScale = scale / initialScale;
                    /*positionX = originX * (1 - relativeScale) + initialPositionX + moveX;
                    positionY = originY * (1 - relativeScale) + initialPositionY + moveY;*/

                  } else {
                    scale = Math.round(scale * 100) / 100
                  }

                $element.css({fontSize: scale + 'rem'});

                console.log('pinchToZoom scale ', scale);
              
            }

        }

    }
})();

