(function() {
    'use strict';

    angular
        .module('app.layout')
        .controller('TitleController', TitleController);

    TitleController.$inject = ['$transitions', '$element'];
    /* @ngInject */
    function TitleController($transitions, $element) {
    //console.log('TitleController $element ', $element);

        var title = $element[0].innerText;

        $transitions.onStart({}, onStart);

        function onStart (trans) {

            var pageTitle = (trans.to().data && trans.to().data.title) ? trans.to().data.title : title;

            setTitle(title + ' - ' + pageTitle);
            
        }

        function setTitle (pageTitle) {

            $element.text(pageTitle);
        }
        

    }

})();
