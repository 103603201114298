(function() {
    'use strict';

    angular
        .module('app.layout')
        .directive('swipeToState', swipeToState);

    swipeToState.$inject = ['$swipe', 'TweenLite', 'LayoutService'];  
    /* @ngInject */
    function swipeToState ($swipe, TweenLite, LayoutService) {
        //console.log('swipeToState...');

        var directive = {
            link: link,
            restrict: 'A'
        };

        return directive;

        function link(scope, $element, attrs) {
            //console.log('swipeToState $element', $element);
            //console.log('swipeToState scope.windowWidth', scope.windowWidth);
            //console.log('swipeToState attrs', attrs);

            var startX,
                snapTreshold = 0.05,
                elWidth,
                shellIndex,
                right,
                snapBack = false,
                view,
                animating = false,
                viewClass = attrs.swipeToState;

            $swipe.bind($element, {
                start: swipeStart,
                move: swipeMove,
                end: swipeEnd,
                cancel: function(event) {
                    swipeEnd({}, event);
                }
            });

            $element.bind('mouseleave', function(event) {
                swipeEnd({}, event);
            });

            function swipeStart(coords, event) {
                //console.log('swipeToState swipeStart view', view);

                updateContainerWidth();

                shellIndex = LayoutService.getStateIndex();

                //console.log('swipeStart shellIndex', shellIndex);

                startX = coords.x;

                view = $element[0].querySelector(viewClass);

                animating = true;
                
            }

            function swipeMove(coords, event) {
                //console.log('swipeMove', coords, event);
                //console.log('swipeMove shellIndex', shellIndex);

                var delta = startX - coords.x;

                if (animating) {
                    TweenLite.set(view, {x: -delta}); 
                }     
            }

            function swipeEnd(coords, event) {
                //console.log('swipeEnd', coords, event);

                if (animating) {

                    animating = false;
                    
                    var delta = startX - coords.x,
                        index;

                    right = delta < 0;

                    index = right ? shellIndex - 1 : shellIndex + 1;

                    if (Math.abs(delta) > elWidth * snapTreshold) {
                        //console.log('swipeEnd delta < 0 ? elWidth : -elWidth', delta < 0 ? elWidth : -elWidth);

                        shellIndex = index;        

                        snapBack = false;

                        TweenLite.to(view, 0.8, {x: (right ? elWidth : -elWidth), ease:Power2.easeOut, onComplete: animationFinish});
                        

                    } else {
                        //console.log('swipeEnd delta', delta);
                        
                        snapBack = true;

                        TweenLite.to(view, 0.4, {x: 0, ease:Power2.easeInOut, onComplete: animationFinish});
                        
                    }
                }     
            }

            function animationFinish() {
                //console.log('animationFinish...');

                if (!snapBack) {

                    LayoutService.swipeToState(shellIndex);

                } 

                snapBack = false;  

            }

            function updateContainerWidth() {
                var rect = $element[0].getBoundingClientRect();
                elWidth = rect.width ? rect.width : rect.right - rect.left;
            }

        }

    }
})();

