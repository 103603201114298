(function() {
    'use strict';

    var background = {
        controller: BackgroundController,
        templateUrl: 'app/components/layout/background.html'

    };

    BackgroundController.$inject = ['$element', 'LayoutService'];
    /* @ngInject*/ 
    function BackgroundController($element, LayoutService) {
        
        var vm = this;

        vm.$onInit = function () {
            //console.log('BackgroundController vm.$onInit vm ', vm);

            LayoutService.setBackgroundSubscribe(setBackground);

        };

       /* vm.$postLink = function () {
            console.log('BackgroundController vm.$postLink $element.find(img) ', $element.find('img'));

            $element.find('img').css({opacity: 1, zIndex: -1001});
        }*/

        function setBackground(background) {
            //console.log('BackgroundController setBackground background ', background);

            vm.background = background;
        } 
    }
 
    angular
        .module('app.layout')
        .component('background', background);


})();