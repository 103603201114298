(function() {
    'use strict';

    var contactForm = {
        controller: ContactFormController,
        templateUrl: 'app/components/layout/contact-form.html'

    };

    ContactFormController.$inject = ['$timeout', 'RestService', 'LayoutService'];
    /* @ngInject */
    function ContactFormController($timeout, RestService, LayoutService) {
        //console.log('ContactFormController START $scope ', $scope);
        
        var vm = this;

        var formTemplate = {
            
            "field_jmeno": "beze jména",
            "field_e_mail": "prazdne@pole.cz",
            "field_telefon": "000 000 000",
            "subject": "bez předmětu",
            "message": "bez správy"

        };

        

        vm.$onInit = function () {
            //console.log('ContactFormController $onInit this ', this);

            vm.form = RestService.getStoredForm();

            if (!vm.form) {

                vm.form = {};

            }

        };

        vm.$onDestroy = function () {
            RestService.storeForm(vm.form);
        };

        vm.submit = function() {
        //console.log('vm.submit vm.form ', vm.form);

            RestService.submitForm(drupalizeForm(vm.form))
                .then(submitSuccess)
                .catch(submitError);
            
        };

        vm.focus = function () {
        //console.log('vm.focus');

            vm.successMsg = false;
            vm.errorMsg = false;

            LayoutService.disableKeyboard(true);

        };

        vm.blur = function () {
        //console.log('vm.blur');

            LayoutService.disableKeyboard(false);

        };

        function drupalizeForm(form) {

            var drupalForm = {};
            
            angular.forEach(formTemplate, function(obj, key) {
            //console.log('drupalizeForm obj ', obj);
            //console.log('drupalizeForm key ', key);

            //console.log('drupalizeForm formTemplate[key] ', formTemplate[key]);

                var value = form[key] === undefined ? formTemplate[key] : form[key];

                drupalForm[key] = [{"value": value}];

                if (key === "field_jmeno" && form[key] !== undefined) {
                    drupalForm["name"] = [{"value": form[key]}];
                }

            });

            drupalForm["contact_form"] = [{"target_id":"kontaktni_formular"}];

            return drupalForm;
        } 

        function submitSuccess (data) {
        //console.log('submitSuccess data ', data);

            vm.form = {};

            vm.successMsg = true;
            vm.errorMsg = false;
        }

        function submitError(data) {
        //console.log('submitError data ', data);

            //vm.form = {};

            
            vm.errorMsg = true;
            vm.successMsg = false;
        }
      
    }
 
    angular
        .module('app.layout')
        .component('contactForm', contactForm);


})();